"use client";
import React, { useState, useEffect, useRef, useCallback } from "react";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { X } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import BookNowButton from "@/components/ui/BookNowButton";
import { useAnimationDisableToggle } from "@/components/wrappers/AnimationDisableProvider";
import { useColors } from "@/components/wrappers/ColorProvider";
import { useColorContrast } from "@/components/wrappers/ColorContrastProvider";

const getSkipLinkHref = (pathname: string) => {
  switch (pathname) {
    case "/":
      return "#hero-section";
    case "/about":
      return "#about-hero";
    case "/services":
      return "#services-hero";
    case "/contact":
      return "#contact-hero";
    case "/accessibility-statement":
      return "#accessibility-statement";
    case "/privacy-policy":
      return "#privacy-policy";
    case "/terms-and-conditions":
      return "#terms-and-conditions";
    case "/not-found":
      return "#not-found";
    default:
      return "#main-content";
  }
};

const Navigation: React.FC = () => {
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [isNavVisible, setIsNavVisible] = useState(true);
  const [pageBackground, setPageBackground] = useState<string>("transparent");
  const menuRef = useRef<HTMLDivElement>(null);
  const logoRef = useRef<HTMLAnchorElement>(null);
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const scrollPosition = useRef(0);

  const { animationsDisabled } = useAnimationDisableToggle();
  const { isDarkMode, colors } = useColors();
  const { contrastMode } = useColorContrast();
  const pathname = usePathname();
  const isServicesPage = pathname === "/services";

  // Function to check if device is mobile
  const isMobile = () => {
    if (typeof window === 'undefined') return false;
    return window.innerWidth <= 768;
  };

  // Background detection with immediate check on mount and route change
  const detectPageBackground = useCallback(() => {
    requestAnimationFrame(() => {
      const heroSection = document.querySelector('[id$="-hero"]');
      const mainContent = document.querySelector("main");
      const targetElement = heroSection || mainContent;

      if (targetElement) {
        const classes = targetElement.className.split(" ");
        const backgroundClass = classes.find(
          (cls) => cls === "" || cls === "" || cls === ""
        );
        setPageBackground(backgroundClass || "transparent");
      }
    });
  }, []);

  // Handle route changes
  useEffect(() => {
    // Reset states on route change
    setPageBackground("transparent");
    setHasScrolled(false);
    
    // Check background multiple times to ensure it's detected after route transition
    const checkTimes = [0, 100, 300];
    checkTimes.forEach(time => {
      setTimeout(detectPageBackground, time);
    });

    // Reset scroll position
    if (!isMenuActive) {
      window.scrollTo(0, 0);
    }
  }, [pathname, detectPageBackground, isMenuActive]);

  // Handle scroll behavior
  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.scrollY;
      const isMobileDevice = isMobile();

      // Update hasScrolled state
      setHasScrolled(currentScroll > 0);

      if (isMobileDevice && !isMenuActive) {
        setIsNavVisible(currentScroll === 0);
      } else {
        setIsNavVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMenuActive]);

  useEffect(() => {
    const loaderTimeout = setTimeout(() => setIsLoaded(true), 500);
    return () => clearTimeout(loaderTimeout);
  }, []);

  const disableScroll = useCallback(() => {
    scrollPosition.current = window.scrollY;
    document.body.style.overflow = "";
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollPosition.current}px`;
    document.body.style.width = "100%";
  }, []);

  const enableScroll = useCallback(() => {
    document.body.style.removeProperty("overflow");
    document.body.style.removeProperty("position");
    document.body.style.removeProperty("top");
    document.body.style.removeProperty("width");
    window.scrollTo(0, scrollPosition.current);
  }, []);

  const handleFocusTrap = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Tab" && isMenuActive) {
        const focusableElements = menuRef.current?.querySelectorAll(
          'a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );
        const firstElement = focusableElements?.[0] as HTMLElement;
        const lastElement = focusableElements?.[
          focusableElements.length - 1
        ] as HTMLElement;

        if (e.shiftKey && document.activeElement === firstElement) {
          e.preventDefault();
          lastElement?.focus();
        } else if (!e.shiftKey && document.activeElement === lastElement) {
          e.preventDefault();
          firstElement?.focus();
        }
      }
    },
    [isMenuActive]
  );

  const handleSkipToWidget = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const widget = document.querySelector('#animation-toggle');
    if (widget instanceof HTMLElement) {
      const widgetButton = widget.querySelector('button');
      if (widgetButton instanceof HTMLElement) {
        widgetButton.focus();
      } else {
        widget.setAttribute('tabindex', '-1');
        widget.focus();
      }
      widget.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSkipToContent = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const targetId = getSkipLinkHref(pathname);
    const cleanId = targetId.replace("#", "");
    const targetElement = document.getElementById(cleanId);
    if (targetElement instanceof HTMLElement) {
      targetElement.setAttribute("tabindex", "-1");
      targetElement.focus();
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleEscapeKey = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape" && isMenuActive) {
        setIsMenuActive(false);
      }
    },
    [isMenuActive]
  );

  useEffect(() => {
    if (isMenuActive) {
      disableScroll();
      document.addEventListener("keydown", handleFocusTrap);
      document.addEventListener("keydown", handleEscapeKey);
      closeButtonRef.current?.focus();
    } else {
      enableScroll();
      document.removeEventListener("keydown", handleFocusTrap);
      document.removeEventListener("keydown", handleEscapeKey);
    }
  }, [
    isMenuActive,
    disableScroll,
    enableScroll,
    handleFocusTrap,
    handleEscapeKey,
  ]);

  const toggleMenu = () => {
    setIsMenuActive((prev) => !prev);
    // Force background check when menu is toggled
    detectPageBackground();
  };

  const menuVariants = {
    closed: {
      x: "100%",
      transition: {
        duration: animationsDisabled ? 0 : 0.3,
        ease: "easeOut",
      },
    },
    open: {
      x: 0,
      transition: {
        duration: animationsDisabled ? 0 : 0.3,
        ease: "easeOut",
      },
    },
  };

  const fadeInVariant = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: animationsDisabled ? 0 : 0.3 },
    },
  };

  const hamburgerVariants = {
    topBar: {
      open: { rotate: 45, y: 8 },
      closed: { rotate: 0, y: -4 },
    },
    middleBar: {
      open: { opacity: 0 },
      closed: { opacity: 1 },
    },
    bottomBar: {
      open: { rotate: -45, y: -8 },
      closed: { rotate: 0, y: 4 },
    },
  };

  const hamburgerTransition = {
    duration: animationsDisabled ? 0 : 0.8,
    ease: [0.645, 0.045, 0.355, 1.0],
  };

  const getNavBackground = () => {
    if (!hasScrolled) {
      return "bg-transparent";
    }
    return pageBackground !== "transparent" ? pageBackground : "";
  };

  const getLogoSrc = () => {
    if (isServicesPage) {
      return contrastMode === "inverted"
        ? "/images/logos/design-dojo-full.svg"
        : "/images/logos/design-dojo-full-white.svg";
    }

    const currentBg = getNavBackground();
    if (currentBg === "" || isDarkMode) {
      return contrastMode === "inverted"
        ? "/images/logos/design-dojo-full.svg"
        : "/images/logos/design-dojo-full-white.svg";
    }
    return contrastMode === "inverted"
      ? "/images/logos/design-dojo-full-white.svg"
      : "/images/logos/design-dojo-full.svg";
  };

  const getHamburgerColor = () => {
    if (isServicesPage) {
      return contrastMode === "inverted" ? "bg-blue" : "bg-white";
    }

    const currentBg = getNavBackground();
    if (currentBg === "bg-blue" || isDarkMode) {
      return contrastMode === "inverted" ? "bg-blue" : "bg-white";
    }
    return contrastMode === "inverted" ? "bg-white" : "bg-blue";
  };

  const getMenuBackgroundColor = () => {
    if (isDarkMode) {
      return contrastMode === "inverted" ? "bg-white" : "bg-black";
    }
    return contrastMode === "inverted" ? "bg-black" : "bg-light";
  };

  const focusClass = isDarkMode ? "focus-button-dark" : "focus-button";

  return (
    <>
      <a
        href="#animation-toggle"
        onClick={handleSkipToWidget}
        className={`sr-only focus:not-sr-only focus:fixed focus:top-2 focus:left-4 
                    text-lg bg-blue text-white px-8 py-6 z-[60] ${focusClass}`}
      >
       Animation Toggle
      </a>
      <a
        href={getSkipLinkHref(pathname)}
        onClick={handleSkipToContent}
        className={`sr-only focus:not-sr-only focus:fixed focus:top-4 focus:left-4 
                  text-lg bg-blue text-white px-8 py-6 z-[60] ${focusClass}`}
      >
        Skip to Main Content
      </a>

      <AnimatePresence>
        {isLoaded && (
          <motion.nav
            id="navigation"
            className={`fixed top-0 w-full z-50 transition-transform duration-300 ${
              !isNavVisible ? '-translate-y-full' : 'translate-y-0'
            }`}
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={fadeInVariant}
          >
            <div
              className={`transition-colors duration-300 ${getNavBackground()}`}
            >
              <div className="mx-auto px-4">
                <div className="flex justify-between items-center h-20">
                  <Link
                    href="/"
                    passHref
                    className={`inline-block ${focusClass}`}
                    ref={logoRef}
                    tabIndex={0}
                  >
                    <Image
                      src={getLogoSrc()}
                      alt="Design Dojo Logo"
                      width={200}
                      height={50}
                      aria-label="Designdojo logo that is Link to home page"
                      className="cursor-pointer w-[150px] sm:w-[200px]"
                      priority
                    />
                  </Link>
                  <motion.button
                    className={`w-12 h-12 flex flex-col justify-center items-center group ${focusClass}`}
                    onClick={toggleMenu}
                    whileTap={{ scale: !animationsDisabled ? 0.95 : 1 }}
                    whileHover={{ scale: !animationsDisabled ? 1.05 : 1 }}
                    aria-expanded={isMenuActive}
                    aria-label={isMenuActive ? "Close menu" : "Open menu"}
                  >
                    <motion.span
                      animate={
                        isMenuActive
                          ? hamburgerVariants.topBar.open
                          : hamburgerVariants.topBar.closed
                      }
                      transition={hamburgerTransition}
                      className={`block h-1 w-10 rounded-sm ml-auto ${getHamburgerColor()}`}
                    />
                    <motion.span
                      animate={
                        isMenuActive
                          ? hamburgerVariants.middleBar.open
                          : hamburgerVariants.middleBar.closed
                      }
                      transition={hamburgerTransition}
                      className={`block h-1 w-8 rounded-sm my-1 ml-auto ${getHamburgerColor()}`}
                    />
                    <motion.span
                      animate={
                        isMenuActive
                          ? hamburgerVariants.bottomBar.open
                          : hamburgerVariants.bottomBar.closed
                      }
                      transition={hamburgerTransition}
                      className={`block h-1 w-6 rounded-sm ml-auto ${getHamburgerColor()}`}
                    />
                  </motion.button>
                </div>
              </div>
            </div>

            <AnimatePresence mode="wait">
            {isMenuActive && (
                <motion.div
                  ref={menuRef}
                  initial="closed"
                  animate="open"
                  exit="closed"
                  variants={menuVariants}
                  className={`fixed inset-0 z-40 ${getMenuBackgroundColor()} text-${
                    colors.blue
                  }`}
                  style={{
                    height: "100vh",
                    WebkitTransform: "translateZ(0)",
                    transform: "translateZ(0)",
                    WebkitBackfaceVisibility: "hidden",
                    backfaceVisibility: "hidden",
                  }}
                  role="dialog"
                  aria-modal="true"
                  aria-label="Navigation menu"
                >
                  <motion.button
                    onClick={toggleMenu}
                    className={`absolute top-0 right-0 m-4 ${focusClass}`}
                    whileHover={{ scale: !animationsDisabled ? 1.1 : 1 }}
                    whileTap={{ scale: !animationsDisabled ? 0.95 : 1 }}
                    aria-label="Close menu"
                    ref={closeButtonRef}
                  >
                    <X size={48} />
                  </motion.button>

                  <nav
                    className="w-full h-full flex flex-col text-center items-center justify-center"
                    aria-label="Main navigation"
                  >
                    <ul className="text-3xl space-y-4">
                      {["/", "about", "services", "contact"].map(
                        (path, index) => (
                          <li key={path || "home"}>
                            <Link
                              href={`/${path === "/" ? "" : path}`}
                              onClick={() => {
                                toggleMenu();
                                // Force background check after navigation
                                setTimeout(detectPageBackground, 100);
                              }}
                              className={`${focusClass}`}
                            >
                              {
                                [
                                  "Home",
                                  "About",
                                  "Services",
                                  "Contact",
                                ][index]
                              }
                            </Link>
                          </li>
                        )
                      )}
                    </ul>
                    <div className="mt-8">
                      <BookNowButton
                        showArrow={true}
                        buttonText="Book Consultation"
                        className={`${focusClass}`}
                      />
                    </div>
                  </nav>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.nav>
        )}
      </AnimatePresence>
    </>
  );
};

export default Navigation;
