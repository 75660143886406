"use client";

import React, { createContext, useContext, useState, useEffect, ReactNode, useCallback } from 'react';

interface DyslexicFontContextProps {
  isDyslexic: boolean;
  toggleDyslexic: () => void;
  resetDyslexicFont: () => void;
}

const DyslexicFontContext = createContext<DyslexicFontContextProps | undefined>(undefined);

const LOCAL_STORAGE_KEY = 'accessibility_dyslexic_settings';

export const useDyslexicFont = () => {
  const context = useContext(DyslexicFontContext);
  if (context === undefined) {
    throw new Error('useDyslexicFont must be used within a DyslexicFontProvider');
  }
  return context;
};

export const DyslexicFontProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isDyslexic, setIsDyslexic] = useState<boolean>(false);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const loadSettings = () => {
      try {
        const savedSettings = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (savedSettings) {
          const settings = JSON.parse(savedSettings);
          if (settings.expiration && new Date().getTime() < settings.expiration) {
            setIsDyslexic(settings.isDyslexic);
          } else {
            localStorage.removeItem(LOCAL_STORAGE_KEY);
          }
        }
      } catch (error) {
        console.error('Error loading dyslexic font settings:', error);
      }
      setIsInitialized(true);
    };

    loadSettings();
    window.addEventListener('storage', loadSettings);
    return () => window.removeEventListener('storage', loadSettings);
  }, []);

  useEffect(() => {
    if (isInitialized) {
      try {
        const settings = {
          isDyslexic,
          expiration: new Date().getTime() + 30 * 24 * 60 * 60 * 1000,
        };
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(settings));
        window.dispatchEvent(new Event('dyslexicSettingsChanged'));
      } catch (error) {
        console.error('Error saving dyslexic font settings:', error);
      }
    }
  }, [isDyslexic, isInitialized]);

  useEffect(() => {
    if (isInitialized) {
      const styleTag = document.createElement('style');
      styleTag.id = 'dyslexic-font-styles';
      
      if (isDyslexic) {
        styleTag.textContent = `
          #main-content * {
            font-family: OpenDyslexic, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
            letter-spacing: 0.35px !important;
            word-spacing: 2px !important;
          }

          #main-content h1, 
          #main-content h2, 
          #main-content h3, 
          #main-content h4, 
          #main-content h5, 
          #main-content h6 {
            letter-spacing: 0.5px !important;
            word-spacing: 4px !important;
          }
        `;
      }

      const existingStyle = document.getElementById('dyslexic-font-styles');
      if (existingStyle) {
        existingStyle.remove();
      }
      
      document.head.appendChild(styleTag);

      // Also apply font to root for immediate effect
      const root = document.documentElement;
      if (isDyslexic) {
        root.style.setProperty('--font-family', 'OpenDyslexic, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif');
        root.classList.add('font-dyslexic');
      } else {
        root.style.removeProperty('--font-family');
        root.classList.remove('font-dyslexic');
      }
    }
  }, [isDyslexic, isInitialized]);

  const toggleDyslexic = useCallback(() => {
    setIsDyslexic(prev => !prev);
  }, []);

  const resetDyslexicFont = useCallback(() => {
    setIsDyslexic(false);
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    const styleTag = document.getElementById('dyslexic-font-styles');
    if (styleTag) {
      styleTag.remove();
    }
    const root = document.documentElement;
    root.style.removeProperty('--font-family');
    root.classList.remove('font-dyslexic');
  }, []);

  return (
    <DyslexicFontContext.Provider 
      value={{ 
        isDyslexic, 
        toggleDyslexic, 
        resetDyslexicFont 
      }}
    >
      {children}
    </DyslexicFontContext.Provider>
  );
};

export { DyslexicFontContext };