"use client";

import React from "react";
import { LineHeightProvider } from "./LineHeightProvider";
import { ColorProvider } from "./ColorProvider";
import AnimationDisableProvider from "./AnimationDisableProvider";
import { SimpleTextProvider } from "./SimpleTextProvider";
import ClientWrapperContent from "./ClientWrapperContent";
import { DyslexicFontProvider } from "./DyslexicFontProvider";
// import { ScreenReaderProvider } from "./ScreenReaderProvider";
import { ColorContrastProvider } from "./ColorContrastProvider";
import { HighlightedLinksProvider } from "./HighlightedLinksProvider";
// import { ZoomProvider } from "./ZoomProvider";


interface ClientWrapperProps {
  children: React.ReactNode;
}

const ClientWrapper: React.FC<ClientWrapperProps> = ({ children }) => {
  return (
    <ColorProvider>
      <DyslexicFontProvider>
      <HighlightedLinksProvider>
        <LineHeightProvider>
          {/* <ScreenReaderProvider> */}
            <ColorContrastProvider>
              <AnimationDisableProvider>
              {/* <ZoomProvider> */}
                <SimpleTextProvider>
                  <ClientWrapperContent>{children}</ClientWrapperContent>
                </SimpleTextProvider>
                {/* </ZoomProvider> */}
              </AnimationDisableProvider>
            </ColorContrastProvider>
          {/* </ScreenReaderProvider> */}
        </LineHeightProvider>
        </HighlightedLinksProvider>
      </DyslexicFontProvider>
    </ColorProvider>
  );
};
export default ClientWrapper;
