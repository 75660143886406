"use client";

import React, { useState, useEffect, ReactNode, Suspense } from "react";
import { usePathname, useSearchParams } from "next/navigation";

interface GlobalLoaderProps {
  children: ReactNode;
}

const EXTENDED_LOADING_ROUTES = [
  "/accessibility-statement",
  "/privacy-policy",
  "/terms-and-conditions",
];

// Separate component for search params functionality
const LoaderContent: React.FC<{ pathname: string; children: ReactNode }> = ({ pathname, children }) => {
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [announcement, setAnnouncement] = useState("");
  const searchParams = useSearchParams();
  
  const isExtendedLoadingRoute = EXTENDED_LOADING_ROUTES.includes(pathname);

  const [prevPathKey, setPrevPathKey] = useState("");
  const currentPathKey = `${pathname}?${searchParams}`;

  useEffect(() => {
    if (prevPathKey && prevPathKey !== currentPathKey) {
      setIsTransitioning(true);
      setAnnouncement("Page content is updating");

      const loadingTime = isExtendedLoadingRoute ? 3300 : 300;
      
      const timer = setTimeout(() => {
        setIsTransitioning(false);
        setAnnouncement("Page content has finished updating");
      }, loadingTime);

      return () => clearTimeout(timer);
    }
    setPrevPathKey(currentPathKey);
  }, [pathname, searchParams, currentPathKey, prevPathKey, isExtendedLoadingRoute]);

  useEffect(() => {
    setIsTransitioning(true);
    setAnnouncement("Page content is updating");

    const loadingTime = isExtendedLoadingRoute ? 3300 : 300;
    
    const timer = setTimeout(() => {
      setIsTransitioning(false);
      setAnnouncement("Page content has finished updating");
    }, loadingTime);

    return () => clearTimeout(timer);
  }, [isExtendedLoadingRoute]);

  return (
    <>
      <div role="status" aria-live="polite" className="sr-only">
        {announcement}
      </div>
      {isTransitioning && <LoadingSpinner />}
      <div aria-busy={isTransitioning}>
        {children}
      </div>
    </>
  );
};

// Fallback loading component
const LoadingFallback = () => (
  <div className="w-full h-full flex items-center justify-center">
    <LoadingSpinner />
  </div>
);

const GlobalLoader: React.FC<GlobalLoaderProps> = ({ children }) => {
  const pathname = usePathname();

  return (
    <Suspense fallback={<LoadingFallback />}>
      <LoaderContent pathname={pathname}>
        {children}
      </LoaderContent>
    </Suspense>
  );
};

const LoadingSpinner: React.FC = () => (
  <div
    className="fixed inset-0 z-[9999] flex items-center justify-center bg-light dark:bg-dark"
    role="status"
    aria-label="Loading content"
  >
    <div className="h-12 w-12 animate-spin rounded-full border-b-2 border-gray-900 dark:border-white"></div>
  </div>
);

export default GlobalLoader;