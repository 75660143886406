"use client";

import React, { useState, useEffect, useRef } from "react";
import Image from "next/image";
import Link from "next/link";
import { useRouter, usePathname } from "next/navigation";
import emailjs from "@emailjs/browser";
import { CheckCircle, Loader2, ExternalLink } from "lucide-react";
import { motion } from "framer-motion";
import { NAV_ITEMS, LEGAL_LINKS, SOCIAL_LINKS } from "../../data";
import AnimatedButton from "../ui/AnimatedButton";
import { useAnimationDisableToggle } from "@/components/wrappers/AnimationDisableProvider";
import { smootherRef } from "@/components/wrappers/ClientWrapperContent";
import Toast from "../ui/Toast";

type SubmissionStatus = "idle" | "submitting" | "submitted";

const Footer: React.FC = () => {
  const router = useRouter();
  const footerRef = useRef<HTMLElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const [email, setEmail] = useState("");
  const [status, setStatus] = useState<SubmissionStatus>("idle");
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const { animationsDisabled } = useAnimationDisableToggle();
  const pathname = usePathname();

  useEffect(() => {
    emailjs.init("V-Z7vRPIpM4Kb08Z9");
  }, []);

  const scrollToTop = () => {
    if (smootherRef) {
      smootherRef.scrollTo(0);
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const handleLinkClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
    path: string
  ) => {
    e.preventDefault();
    scrollToTop();

    // Use Next.js router.push instead of window.location
    setTimeout(() => {
      router.push(path);
    }, 300); // Reduced timeout for better UX
  };

  const showToastMessage = (message: string) => {
    setToastMessage(message);
    setShowToast(true);
    setTimeout(() => setShowToast(false), 5000);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setStatus("submitting");

    try {
      await emailjs.send(
        "service_v01n5wi",
        "template_d247qk8",
        {
          from_email: email,
        },
        "V-Z7vRPIpM4Kb08Z9"
      );

      setStatus("submitted");
      setEmail("");
      showToastMessage("Thank you for subscribing!");
    } catch (error) {
      console.error("Failed to send email:", error);
      setStatus("idle");
      showToastMessage("Failed to send email. Please try again.");
    }
  };

  const linkClasses =
    "focus:outline-none focus-visible:ring-4 focus-visible:ring-light focus-visible:ring-opacity-100 focus-visible:ring-offset-2 focus-visible:ring-offset-blue hover:ring-4 hover:ring-light hover:ring-opacity-100 hover:ring-offset-2 hover:ring-offset-blue transition-shadow duration-300 rounded group p-1";

  const isLinkActive = (path: string) => {
    return pathname === path;
  };

  const isSubmitting = status === "submitting";

  return (
    <div className="footer-wrapper" id="footer">
      <footer
        ref={footerRef}
        className="bg-blue text-light py-0 lg:py-8 w-full relative overflow-hidden"
        role="contentinfo"
        aria-label="Site Footer"
      >
        <div
          ref={contentRef}
          className="custom-container mx-auto px-4 footer-animated-element relative z-10"
        >
          <div className="flex flex-col lg:flex-row items-center lg:items-start justify-between">
            {/* Logo Section */}
            <div className="w-full lg:w-1/4 flex justify-center lg:justify-start">
              <Image
                src="/images/logos/design-dojo-full-white.svg"
                alt="DesignDojo Logo"
                width={200}
                height={60}
                className="mb-6"
              />
            </div>

            {/* Main Footer Content Grid */}
            <div className="grid grid-cols-1 lg:grid-cols-5 gap-8 lg:gap-0 w-full">
              {/* Navigation Links */}
              <div className="lg:col-span-1 pr-4 lg:pr-8">
                <nav aria-label="Footer Navigation">
                  <ul className="space-y-2 text-left text-xl lg:pl-16">
                    {NAV_ITEMS.map((item, idx) => (
                      <li key={idx}>
                        <Link
                          href={item.path}
                          className={`inline-block ${linkClasses} ${
                            isLinkActive(item.path)
                              ? "text-yellow"
                              : "text-light hover:text-yellow focus-visible:text-yellow"
                          }`}
                          data-nav-item={item.name.toLowerCase()}
                          aria-current={
                            isLinkActive(item.path) ? "page" : undefined
                          }
                          onClick={(e) => handleLinkClick(e, item.path)}
                        >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>

              {/* Legal Links */}
              <div className="lg:col-span-1">
                <nav aria-label="Legal Information">
                  <ul className="space-y-4 pt-0 lg:pt-2 text-gray-400 text-md legal-links">
                    {LEGAL_LINKS.map((item, idx) => (
                      <li key={idx}>
                        {!animationsDisabled ? (
                          <Link
                            href={item.path}
                            className={` ${linkClasses} hover:text-white focus-visible:text-white`}
                            aria-label={item.name}
                            onClick={(e) => handleLinkClick(e, item.path)}
                          >
                            {item.name}
                          </Link>
                        ) : (
                          <Link
                            href={item.path}
                            className={`${linkClasses} hover:text-white focus-visible:text-white`}
                            aria-label={item.name}
                            onClick={(e) => handleLinkClick(e, item.path)}
                          >
                            {item.name}
                          </Link>
                        )}
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>

              {/* Newsletter Section */}
              <div className="lg:col-span-3 flex flex-col justify-start lg:pl-20">
                <h2 className="text-4xl xs:text-5xl md:text-6xl text-light mb-4 focus-text-dark">
                  Keep in Touch!
                </h2>
                <p className="mb-4 text-md xs:text-lg md:text-xl focus-text-dark">
                  Subscribe to our newsletter
                </p>

                {status === "submitting" && (
                  <div
                    className="flex flex-col items-center justify-center"
                    aria-live="polite"
                  >
                    <Loader2
                      size={60}
                      className="animate-spin text-primary mb-4"
                      aria-hidden="true"
                    />
                    <h2 className="text-xl font-bold text-light">
                      Submitting...
                    </h2>
                  </div>
                )}

                {status === "submitted" && (
                  <div
                    className="flex flex-col items-center justify-center"
                    aria-live="polite"
                  >
                    <CheckCircle
                      size={60}
                      className="text-green-500 mb-4"
                      aria-hidden="true"
                    />
                    <h2 className="text-xl font-bold text-light">Thank you!</h2>
                    <p className="text-center text-light">
                      You&apos;ve successfully subscribed to our newsletter.
                    </p>
                  </div>
                )}

                {status === "idle" && (
                  <form
                    ref={formRef}
                    onSubmit={handleSubmit}
                    className="flex flex-col space-y-4"
                    aria-labelledby="newsletter-signup"
                  >
                    <h2 id="newsletter-signup" className="sr-only">
                      Newsletter Signup
                    </h2>

                    <div className="flex flex-col space-y-2">
                      <label
                        htmlFor="newsletter-email"
                        className="text-sm font-medium text-light"
                      >
                        Please Enter Your Email Address{" "}
                        <span className="text-light">*</span>
                      </label>
                      <input
                        type="email"
                        id="newsletter-email"
                        name="email"
                        placeholder="Your Email*"
                        className="w-full p-2 border border-gray-300 rounded-lg focus-button-dark text-blue placeholder-gray-500 text-sm"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        aria-required="true"
                        autoComplete="email"
                      />
                    </div>

                    <AnimatedButton
                      type="submit"
                      variant="secondary"
                      className="transition-all duration-300 focus-button-dark"
                      aria-label="Subscribe to our newsletter"
                      showArrow
                    >
                      {isSubmitting ? "Sending..." : "Subscribe"}
                    </AnimatedButton>
                  </form>
                )}
              </div>
            </div>
          </div>

          {/* Footer Bottom Section */}
          <div className="mt-8 md:mt-16 pt-8 border-t border-gray-400 flex flex-col items-center md:flex-row md:justify-between">
            <div aria-label="Social Media Links" className="mb-4 md:mb-0">
              <ul className="flex flex-wrap justify-center space-x-4 md:space-x-6">
                {SOCIAL_LINKS.map((social, idx) => (
                  <li key={idx} className="inline-block">
                    <motion.a
                      href={social.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label={`Visit our ${social.name} page (opens in new tab)`}
                      className={`text-gray-400 hover:text-white focus-visible:text-white transition-all duration-300 ease-in-out block p-2 rounded-full ${linkClasses} relative`}
                      whileHover={{
                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                      }}
                      whileFocus={{
                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                      }}
                      transition={{ duration: 0.3 }}
                    >
                      <span
                        className="inline-flex items-center"
                        aria-hidden="true"
                      >
                        <social.icon size={24} />
                      </span>
                      <span
                        className="opacity-0 group-hover:opacity-100 group-focus-visible:opacity-100 transition-opacity absolute -top-4 -right-4"
                        aria-hidden="true"
                      >
                        <ExternalLink size={16} />
                      </span>
                    </motion.a>
                  </li>
                ))}
              </ul>
            </div>

            {/* Copyright */}
            <p className="text-gray-400 text-xs md:text-base mb-4 md:mb-0 text-center focus-text-dark">
              ©{new Date().getFullYear()} - DesignDojo. All Rights Reserved.
            </p>
            <a
              href="https://www.bbb.org/us/ca/sacramento/profile/web-design/dojo-innovations-llc-1156-90097469/#sealclick"
              target="_blank"
              rel="nofollow noopener noreferrer"
              className="group relative inline-flex items-center"
            >
              <Image
                src="https://seal-necal.bbb.org/seals/blue-seal-187-130-whitetxt-bbb-90097469.png"
                width={124}
                height={115}
                alt="Better Business Bureau link for Dojo Innovations, LLC "
                className={`${linkClasses} border-none`}
              />
              <ExternalLink
                size={20}
                className="absolute -top-7 -right-4 transition-opacity duration-200 opacity-0 group-hover:opacity-100 text-blue-500"
                aria-hidden="true"
              />
            </a>

            <a
              href="https://www.w3.org/WAI/WCAG2AA-Conformance"
              className={`mt-4 md:mt-0 ${linkClasses} relative`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="flex items-center ">
                <Image
                  src="https://www.w3.org/WAI/WCAG22/wcag2.2AA-blue"
                  alt="WCAG 2.2 Level AA Conformance badge"
                  width={88}
                  height={32}
                />
              </div>
              <ExternalLink
                size={20}
                className="opacity-0 group-hover:opacity-100 group-focus-visible:opacity-100 transition-opacity absolute -top-7 -right-6 m-1"
                aria-hidden="true"
              />
            </a>
          </div>
        </div>
        <div
  className="absolute z-0 lg:left-0 lg:bottom-20 right-[-16px] top-32 opacity-20 sm:opacity-100 lg:opacity-20 xl:opacity-100 lg:transform-none transform -scale-x-100 lg:scale-x-100"
  aria-hidden="true"
>
  <Image
    src="/images/home/footer-image.svg"
    alt=""
    width={500}
    height={300}
    style={{ width: 'auto', height: 'auto' }} // Maintain aspect ratio if styles are modified
  />
</div>

      </footer>
      <Toast
        message={toastMessage}
        isVisible={showToast}
        onClose={() => setShowToast(false)}
      />
    </div>
  );
};

export default Footer;
