import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

interface ColorScheme {
  background: string;
  foreground: string;
  blue: string;
  light: string;
  cream: string;
  yellow: string;
  brown: string;
}

interface ColorContextType {
  colors: ColorScheme;
  isDarkMode: boolean;
  toggleDarkMode: () => void;
  adjustColorsForBackground: (backgroundHex: string) => void;
}

const lightModeColors: ColorScheme = {
  background: "#F7F2F0",
  foreground: "#1D3557",
  blue: "#1D3557",
  light: "#F7F2F0",
  cream: "#FFF8E1",
  yellow: "#F4B021",
  brown: "#592A14",
};

const ColorContext = createContext<ColorContextType | undefined>(undefined);

export const useColors = () => {
  const context = useContext(ColorContext);
  if (!context) {
    throw new Error('useColors must be used within a ColorProvider');
  }
  return context;
};

export const ColorProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState<boolean>(() => {
    if (typeof window !== 'undefined') {
      const saved = localStorage.getItem('isDarkMode');
      return saved ? JSON.parse(saved) : false;
    }
    return false;
  });

  const [colors, setColors] = useState<ColorScheme>(lightModeColors);

  const generateDarkModeColors = useCallback((backgroundColor: string): ColorScheme => ({
    background: backgroundColor,
    foreground: "#FFFFFF",
    blue: "#1D3557",
    light: "#F7F2F0",
    cream: "#6D4C41",
    yellow: "#F4B021",
    brown: "#FFFFFF",
  }), []);

  useEffect(() => {
    const root = document.documentElement;
    const darkBackground = "#121212";

    if (isDarkMode) {
      root.classList.add('dark');
      setColors(generateDarkModeColors(darkBackground));
    } else {
      root.classList.remove('dark');
      setColors(lightModeColors);
    }

    localStorage.setItem('isDarkMode', JSON.stringify(isDarkMode));
  }, [isDarkMode, generateDarkModeColors]);

  useEffect(() => {
    const root = document.documentElement;

    Object.entries(colors).forEach(([key, value]) => {
      root.style.setProperty(`--${key}-color`, value);
    });

    const isInFooter = (element: Element) => element.closest('#footer') !== null;

    const updateElements = () => {
      document.querySelectorAll('.text-blue').forEach((element) => {
        if (!isInFooter(element)) {
          (element as HTMLElement).style.color = isDarkMode ? '#FFFFFF' : '#1D3557';
        }
      });

      document.querySelectorAll('.bg-light').forEach((element) => {
        if (!isInFooter(element)) {
          (element as HTMLElement).style.backgroundColor = isDarkMode ? '#000000' : '#F7F2F0';
        }
      });

      document.querySelectorAll('.text-light').forEach((element) => {
        if (!isInFooter(element)) {
          (element as HTMLElement).style.color = '#F7F2F0';
        }
      });

      document.querySelectorAll('[class*="text-gray-"]').forEach((element) => {
        if (!isInFooter(element)) {
          const classList = element.className.split(' ');
          const grayClass = classList.find((cls) => cls.startsWith('text-gray-'));

          if (grayClass) {
            const intensity = parseInt(grayClass.split('-')[2]);
            if (isDarkMode) {
              if (intensity >= 600) {
                (element as HTMLElement).style.color = '#FFFFFF';
              } else {
                const darkIntensity = Math.min(900, intensity + 300);
                (element as HTMLElement).style.color = `var(--tw-gray-${darkIntensity})`;
              }
            } else {
              (element as HTMLElement).style.removeProperty('color');
            }
          }
        }
      });
    };

    updateElements();

    const observer = new MutationObserver(updateElements);
    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
      attributeFilter: ['class'],
    });

    return () => observer.disconnect();
  }, [colors, isDarkMode]);

  const toggleDarkMode = useCallback(() => {
    setIsDarkMode((prev) => !prev);
  }, []);

  const adjustColorsForBackground = useCallback((backgroundHex: string) => {
    if (isDarkMode) {
      setColors(generateDarkModeColors(backgroundHex));
    }
  }, [isDarkMode, generateDarkModeColors]);

  return (
    <ColorContext.Provider
      value={{
        colors,
        isDarkMode,
        toggleDarkMode,
        adjustColorsForBackground,
      }}
    >
      {children}
    </ColorContext.Provider>
  );
};

export default ColorProvider;
