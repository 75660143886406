// components/wrappers/GlobalLoaderWrapper.tsx
"use client";

import React, { ReactNode } from "react";
import GlobalLoader from "../ui/GlobalLoader";

interface GlobalLoaderWrapperProps {
  children: ReactNode;
}

const GlobalLoaderWrapper: React.FC<GlobalLoaderWrapperProps> = ({
  children,
}) => {
  return <GlobalLoader>{children}</GlobalLoader>;
};

export default GlobalLoaderWrapper;