
export type WidgetPosition = 
  | 'left-top'
  | 'left-middle'
  | 'left-bottom'
  | 'right-top'
  | 'right-middle'
  | 'right-bottom';
  // export type ZoomLevel = 0 | 1 | 2 | 3 | 4 | 5;
  // export const INITIAL_ZOOM_LEVEL: ZoomLevel = 0;

export const POSITIONS: WidgetPosition[] = [
  'left-top', 'left-middle', 'left-bottom', 
  'right-top', 'right-middle', 'right-bottom'
];
export const INITIAL_POSITION: WidgetPosition = 'left-bottom';
export const INITIAL_READING_SPEED: ReadingSpeed = 'normal';


export type ReadingSpeed = "normal" | "fast" | "slow" | "off";

export const SPEECH_RATES: { [key in ReadingSpeed]: number } = {
  slow: 0.7,
  normal: 1,
  fast: 1.3,
  off: 0,
};

export const getPositionStyle = (position: WidgetPosition): React.CSSProperties => {
  switch (position) {
    case 'left-top':
      return { left: '20px', top: '20px' };
    case 'left-middle':
      return { left: '20px', top: '50%', transform: 'translateY(-50%)' };
    case 'left-bottom':
      return { left: '20px', bottom: '16px' };
    case 'right-top':
      return { right: '20px', top: '20px' };
    case 'right-middle':
      return { right: '20px', top: '50%', transform: 'translateY(-50%)' };
    case 'right-bottom':
      return { right: '80px', bottom: '16px' };
  }
};

export const HIGHLIGHTED_BG_COLOR = 'bg-black';
export const HIGHLIGHTED_TEXT_COLOR = 'text-[#ffe16a]';

export const CONTRAST_MODES = {
  normal: 'normal',
  inverted: 'inverted',

} as const;

export type ContrastMode = typeof CONTRAST_MODES[keyof typeof CONTRAST_MODES];



export interface AccessibilitySettings {
  isScreenReaderActive: boolean;
  readingSpeed: ReadingSpeed;
  // zoomLevel: ZoomLevel;
  isHighlightActive: boolean;
  contrastMode: ContrastMode;
  isDyslexic: boolean;
  isSimple: boolean;
  animationsDisabled: boolean;
  lineHeightLevel: number;
  isDarkMode: boolean;

  toggleAnimations: () => void;
  toggleDarkMode: () => void;
  toggleScreenReader: () => void;
  // setZoomLevel: (level: ZoomLevel) => void;
  toggleSimpleText: () => void;
  toggleDyslexic: () => void;
  setLineHeightLevel: (level: number) => void;
  setContrastMode: (mode: ContrastMode) => void;
  setIsActive: (active: boolean) => void;
}
