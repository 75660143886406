import React, { createContext, useContext, useState, useEffect } from "react";
import { CONTRAST_MODES } from "../../types/accessibility"; // Adjust the path as needed

interface ColorContrastContextProps {
  contrastMode: string;
  toggleContrastMode: () => void;
}

const ColorContrastContext = createContext<ColorContrastContextProps | undefined>(undefined);

export const ColorContrastProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [contrastMode, setContrastMode] = useState<string>(CONTRAST_MODES.normal);

  const toggleContrastMode = () => {
    setContrastMode((prevMode) =>
      prevMode === CONTRAST_MODES.normal ? CONTRAST_MODES.inverted : CONTRAST_MODES.normal
    );
  };

  useEffect(() => {
    const mainContent = document.getElementById("main-content");
    const navigation = document.getElementById("navigation");

    if (!mainContent || !navigation) return;

    // Reset existing classes
    mainContent.className = "";
    navigation.className = "";

    if (contrastMode === CONTRAST_MODES.inverted) {
      mainContent.classList.add("invert", "hue-rotate-180");
      navigation.classList.add("invert", "hue-rotate-180");
    }
  }, [contrastMode]);

  return (
    <ColorContrastContext.Provider value={{ contrastMode, toggleContrastMode }}>
      {children}
    </ColorContrastContext.Provider>
  );
};

export const useColorContrast = () => {
  const context = useContext(ColorContrastContext);
  if (!context) {
    throw new Error("useColorContrast must be used within a ColorContrastProvider");
  }
  return context;
};