// contexts/HighlightedLinksProvider.tsx

import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

interface HighlightedLinksContextProps {
  isHighlightActive: boolean;
  toggleHighlight: () => void;
}

const HighlightedLinksContext = createContext<HighlightedLinksContextProps | undefined>(undefined);

export const HighlightedLinksProvider = ({ children }: { children: ReactNode }) => {
  const [isHighlightActive, setIsHighlightActive] = useState<boolean>(false);

  useEffect(() => {
    const savedSetting = localStorage.getItem('isHighlightActive');
    if (savedSetting !== null) {
      setIsHighlightActive(JSON.parse(savedSetting));
    }
  }, []);

  useEffect(() => {
    const styleId = 'highlighted-links-style';
    let styleTag = document.getElementById(styleId) as HTMLStyleElement;

    if (isHighlightActive) {
      if (!styleTag) {
        styleTag = document.createElement('style');
        styleTag.id = styleId;
        document.head.appendChild(styleTag);
      }

      const bgColor = '#FFFF00'; // Yellow background
      const textColor = '#000000'; // Black text

      styleTag.textContent = `
        #main-content a:not(.no-highlight),
        #main-content button:not(.no-highlight) {
          background-color: ${bgColor};
          color: ${textColor};
          outline: 4px solid ${textColor};
        }
      `;
    } else if (styleTag) {
      styleTag.remove();
    }

    localStorage.setItem('isHighlightActive', JSON.stringify(isHighlightActive));
  }, [isHighlightActive]);

  const toggleHighlight = () => {
    setIsHighlightActive((prev) => !prev);
  };

  return (
    <HighlightedLinksContext.Provider value={{ isHighlightActive, toggleHighlight }}>
      {children}
    </HighlightedLinksContext.Provider>
  );
};

export const useHighlightedLinks = () => {
  const context = useContext(HighlightedLinksContext);
  if (context === undefined) {
    throw new Error('useHighlightedLinks must be used within a HighlightedLinksProvider');
  }
  return context;
};
