import React from 'react';
import { FaYelp, FaTiktok, FaInstagram, FaFacebookF } from "react-icons/fa";
import { TbBrandLinktree } from 'react-icons/tb';

export interface NavItem {
  name: string;
  path: string;
}

export interface SocialLink {
  name: string;
  icon: React.ComponentType<{ size: number }>;
  url: string;
}

export const NAV_ITEMS: NavItem[] = [
  { name: "Home", path: "/" },
  { name: "About", path: "/about" },
  { name: "Services", path: "/services" },
  // { name: "Portfolio", path: "/portfolio" },
  { name: "Contact", path: "/contact" },
];

export const LEGAL_LINKS: { name: string; path: string }[] = [
  { name: "Privacy Policy", path: "/privacy-policy" },
  { name: "Terms & Conditions", path: "/terms-and-conditions" },
  { name: "Accessibility Statement", path: "/accessibility-statement" },
  { name: "Report an Issue", path: "/contact" },
];

export const SOCIAL_LINKS: SocialLink[] = [
  {
    name: "Yelp",
    icon: FaYelp,
    url: "https://www.yelp.com/biz/dojo-innovations-arden-arcade?uid=umMJEBF8NohSojriTHGXeA&utm_campaign=www_business_share_popup&utm_medium=copy_link&utm_source=(direct)",
  },
  {
    name: "Linktree",
    icon: TbBrandLinktree,
    url: "https://linktr.ee/designdojo.io",
  },
  {
    name: "TikTok",
    icon: FaTiktok,
    url: "https://www.tiktok.com/@designdojo.io",
  },
  {
    name: "Instagram",
    icon: FaInstagram,
    url: "https://www.instagram.com/designdojoio/",
  },
  {
    name: "Facebook",
    icon: FaFacebookF,
    url: "https://www.facebook.com/people/DesignDojo/61565903746984/?mibextid=LQQJ4de",
  },
];




export interface TrustMessageProps {
  message: string;
  src: string;
  alt: string;
}

export const trustMessages: TrustMessageProps[] = [
  { message: "BBB Accredited", src: "/images/trust/trust-1.png", alt: "Better Business Bureau seal" },
  { message: "Fully Insured", src: "/images/trust/trust-2.png", alt: "Shield icon representing insurance" },
  { message: "100% Satisfaction", src: "/images/trust/trust-3.png", alt: "Thumbs up icon" },
  { message: "Cutting-Edge Technology", src: "/images/trust/trust-4.png", alt: "Gear and circuit icon" },
  { message: "24/7 Customer Support", src: "/images/trust/trust-5.png", alt: "Headset icon" },
  { message: "6+ Years Experience", src: "/images/trust/trust-6.png", alt: "Calendar icon with number 6" },
];






export interface Plan {
  title: string;
  description: string;
  recieve: string
  details: string[];
}

export const plans: Plan[] = [
  {
    title: "COMPLIANCE STARTER",
    description: "For businesses that need an expert accessibility assessment to start their compliance journey forward.",
  
    recieve: `A thorough understanding of your website's accessibility status and actionable advice on how to fix key issues.`,
    details: [
      "Full website accessibility audit (10 pages)",
      "WCAG 2.2 compliance report",
      "1-hour consultation to discuss findings",
      "Consultation only",
    ],
  },
  {
    title: "ACCESSIBILITY ACCELERATOR",
    description: "For businesses seeking to achieve full compliance and require expert guidance throughout the process.",
    recieve: `Not only will you understand your site's accessibility gaps, but you'll also have detailed guidance on how to make your site compliant.`,
  
    details: [
      "Everything in the Compliance Starter",
      "In-depth exploration of recommended fixes",
      "3 hours of calls for implementation support",
      "Ongoing support for up to one year",
      "Consult + Implementation Support",
    ],
  },
  {
    title: "INCLUSIVE EXPERIENCE BUILDER",
    description: `For businesses looking for a fully custom-built, accessibility-first website that's completely tailored to their needs.`,
    recieve: "A fully accessible, custom-designed website that meets your unique requirements, with long-term support and built-in compliance from day one.",
    details: [
      "Full custom website design & development",
      "Built from the ground up with accessibility at the core",
      "WCAG 2.2 compliance ensured",
      "Long-term support built-in for maintenance",
    ],
  },
];

