"use client";

import React, { useState, useEffect } from "react";
import { FaArrowUp } from "react-icons/fa";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

const ScrollToTopButton: React.FC = () => {
  const [showButton, setShowButton] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const focusClasses =
  "focus:outline-none focus-visible:ring-4 focus-visible:ring-blue focus-visible:ring-opacity-100 focus-visible:ring-offset-2 focus-visible:ring-offset-white hover:ring-4 hover:ring-blue hover:ring-opacity-100 hover:ring-offset-2 hover:ring-offset-white transition-shadow duration-300 rounded";


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;

      if (scrollPosition > 200) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollToTop = () => {
    if (isMobile) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      gsap.to(window, {
        duration: 1,
        scrollTo: { y: 0, autoKill: false },
        ease: "power3.out",
        onComplete: () => {},
      });
    }
  };

  return (
    <>
      {showButton && (
        <button
          onClick={scrollToTop}
          className={`fixed bottom-4 right-4 shadow-md bg-blue border-2 border-light text-light p-3  rounded-full  transition-all duration-300 ease-in-out z-20 ${focusClasses}`}
          aria-label="Scroll to top"
          tabIndex={0}
        >
          <FaArrowUp />
        </button>
      )}
    </>
  );
};

export default ScrollToTopButton;